<template>
  <div class="icon-remixicon">
    <examine :info="myInfo"></examine>
  </div>
</template>
<script>
import examine from './components/examine.vue';
export default {
  name: "IconRemixicon",
  components: {
    examine,
  },
  data() {
    return {
      myInfo: {
        title: '各种协议',
        type: 'AGR',
      }
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style lang="less" scoped>
 .icon-remixicon {
}
</style>
